<template>
  <transition-expand>
    <div v-show="visible" :class="$style.container">
      <div :class="$style.header">
        <span :class="$style.header__title">{{ $t("SpeakerQuestions.askQuestion") }}</span>
        <anonymous-checkbox
          v-if="allowAnonymity"
          id="questionsAnonymous"
          v-model="message.anonymous"
        />
        <ws-button icon color="default" @click="$emit('close')">
          <ws-icon xxl light color="default">times</ws-icon>
        </ws-button>
      </div>
      <div :class="$style.body">
        <textarea
          ref="textarea"
          v-model.trim="message.text"
          :class="$style.body__message"
          rows="5"
          :placeholder="$t('Messenger.startTyping')"
          @keydown.enter.exact.prevent="sendMessage"
          @keydown.ctrl.enter.exact="message.text = `${message.text}\n`"
          @keydown.exact="normalizeMessageText"
        ></textarea>
      </div>
    </div>
  </transition-expand>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";
import AnonymousCheckbox from "@/components/common/messenger/AnonymousCheckbox";
import TransitionExpand from "@/components/common/transitions/TransitionExpand";

export default {
  name: "RoomQuestionsMessenger",
  components: { TransitionExpand, AnonymousCheckbox, WsIcon, WsButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    allowAnonymity: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение
     * {
     *   text: string,
     *   anonymous: boolean,
     * }
     */
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    async visible(val) {
      if (!val) return;
      setTimeout(() => {
        // Ждем анимацию
        this.$refs.textarea.focus();
      }, 300);
    },
  },
  methods: {
    normalizeMessageText() {
      this.message.text = this.message.text.replace(/\n{2,}/g, "\n").replace(/\s{2,}/g, " ");
    },
    sendMessage() {
      this.normalizeMessageText();
      this.$emit("send-message");
    },
  },
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  user-select: none;

  & > :last-child {
    margin-left: 25px;
  }

  &__title {
    flex: 1 1 auto;
    font-size: 17px;
    font-weight: 600;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;

  &__message {
    width: 100%;
    padding: 15px;
    resize: none;
    border: 1px solid $gray-lighten;
    border-radius: 5px;
  }

  &__speakers {
    margin-top: 15px;
  }
}
</style>
