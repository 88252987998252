<template>
  <label :style="styleCustomSizes" :class="$style.radio">
    <input
      :class="$style.input"
      type="radio"
      :checked="$attrs.value === currentValue"
      v-bind="$attrs"
      :name="name"
      @change="$emit('input', $event.target.value)"
    />
    <span :class="$style.dot" />
  </label>
</template>

<script>
/**
 * Базовая компонента радио кнопки
 *
 * @vue-prop {boolean} [sm=false] - Маленькая радио кнопка (используется в селекте спикеров)
 * @vue-prop {boolean} [md=false] - Средняя радио кнопка
 * @vue-prop {string|number} [size=0] - Задать собственный размер для радио кнопки (габаритный размер)
 * @vue-computed {string} currentSizeClass - Класс текущего установленного размера
 * @vue-computed {object} styleCustomSizes - Вычесленние значения переменных от кастомного размера кнопки
 */
import { RADIO_SIZES } from "@/constants/themes/themes-const";

export default {
  name: "WsToggle",
  inheritAttrs: false,
  model: {
    prop: "currentValue",
  },
  props: {
    currentValue: {
      type: [Number, String, Boolean],
      default: "",
    },
    sm: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 0,
    },
    /**
     * Название группы, относящейся к radioButton.
     * Позволяет сделать группу radioButton, для того, чтобы выбрать максимум один ответ
     */
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentSize() {
      return (this.sm && RADIO_SIZES.sm) || (this.md && RADIO_SIZES.md) || RADIO_SIZES.md;
    },
    styleCustomSizes() {
      return {
        "--ws-toggle-size": `${this.size || this.currentSize}px`,
      };
    },
    /**
     * ID чекбокса
     */
    id() {
      return this.$vnode.key || null;
    },
  },
};
</script>

<style lang="scss" module>
.radio {
  --ws-toggle-size-md: 20px;
  --ws-toggle-size: var(--ws-toggle-size-md);
  --ws-toggle-dot-size: calc(var(--ws-toggle-size) * 0.6);
  --ws-toggle-border-size: calc(var(--ws-toggle-size) / 10);

  position: relative;
  display: inline-block;
  width: var(--ws-toggle-size);
  height: var(--ws-toggle-size);
}

.dot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  border: solid $gray;
  border-width: var(--ws-toggle-border-size);
  border-radius: 50%;
  transition: all 0.3s;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--ws-toggle-dot-size);
    height: var(--ws-toggle-dot-size);
    content: "";
    background-color: var(--base-color);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s;
    transform: translate(-50%, -50%);
  }
}

.input {
  width: var(--ws-toggle-size);
  height: var(--ws-toggle-size);
  opacity: 0;

  &:checked + .dot {
    border-color: var(--base-color);

    &::before {
      opacity: 1;
    }
  }

  &:disabled + .dot {
    opacity: 0.4;
  }
}
</style>
