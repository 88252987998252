<template>
  <div
    :class="[
      $style.container,
      {
        [$style.container_answered]: !question.isLine && question.isAnswered,
        [$style.container_line]: question.isLine,
      },
    ]"
  >
    <div v-if="question.isLine" :class="$style.line">
      <span>{{ $t("SpeakerQuestions.answered") }}</span>
    </div>
    <template v-else>
      <div :class="$style.header">
        <div :class="$style.status">
          <ws-icon xs :color="question.isAnswered ? 'green' : 'default'">
            {{ question.isAnswered ? "check" : "clock" }}
          </ws-icon>
          {{ question.fio || $t("common.guest") }}

          <div v-if="question.askUserId && question.fio" :class="$style.userInfo">
            <i class="far fa-user"></i>
            <div :class="$style.userInfoModal">
              <img
                v-if="question.photo"
                :class="$style.userAvatar"
                :src="question.photo"
                :alt="'Аватарка пользователя ' + question.fio"
              />
              <div v-else :class="$style.userDefaultAvatar">
                <i class="fal fa-user"></i>
              </div>

              <p>{{ question.fio }}</p>
              <p>{{ question.position }}</p>
              <!--  TODO: Срочная правка во время резлиза. -->
              <!--  <p>{{ question.company }} </p>-->
            </div>
          </div>
        </div>
        <ws-button sm icon color="default" @click="sendMark('like')">
          <ws-icon xs :color="question.isMarked ? 'primary' : 'default'">thumbs-up</ws-icon>
        </ws-button>
        {{ question.mark }}
        <template v-if="dislikesEnabled">
          <ws-button sm icon color="default" @click="sendMark('dislike')">
            <ws-icon xs :color="question.isDisliked ? 'primary' : 'default'">thumbs-down</ws-icon>
          </ws-button>
          {{ question.dislike }}
        </template>
      </div>
      <div :class="$style.body">
        <span v-for="(row, i) in question.value" :key="i">
          {{ questionValue(row, i) }}
        </span>
      </div>
      <div v-if="hasAnswer" :class="$style.footer">
        <div v-if="question.answer">
          <b>{{ $t("SpeakerQuestions.questionItem.speakerAnswer") }}</b>
          <div :class="$style.answer">
            <span>{{ question.answer }}</span>
          </div>
        </div>

        <div v-if="question.admin_answer">
          <b>{{ $t("SpeakerQuestions.questionItem.managerAnswer") }}</b>
          <div :class="$style.answer">
            <span>{{ question.admin_answer }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

/**
 * Компонента содержит в себе две основные функции Отображение линии отвеченных вопросов и Вопроса,
 * тк они используются в одном списке
 *
 * @vue-prop {boolean} [line=false] - Режим линии отображается только линия
 * @vue-prop {object} question - Вопрос содержит в себе данные вопроса
 * @vue-prop {boolean} [dislikesEnabled=false] - Включенны ли дислайки в настройках
 */
export default {
  name: "QuestionsItem",
  components: { WsIcon, WsButton },
  props: {
    /**
     * Questions item as
     * {
     *   answer: string,
     *   dislike: number,
     *   id: string,
     *   isAnswered: boolean,
     *   isDisliked: boolean,
     *   isLastNotAnswered: boolean,
     *   isMarked: boolean,
     *   mark: number,
     *   speaker: string,
     *   speakerId: string,
     *   stats: boolean,
     *   value: array,
     * }
     */
    question: {
      type: Object,
      default: () => {},
    },
    dislikesEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // Был ли дан ответ на данный вопрос
    hasAnswer() {
      return this.question.answer || this.question.admin_answer;
    },
  },
  methods: {
    /**
     * Метод проставления кавычек в начале и конце массива строк
     *
     * @param {string} text - текст вопроса
     * @param {number} index - позиция ответа
     * @returns {string} - возвращаемый ответ
     */
    questionValue(text, index) {
      return `${index === 0 && this.hasAnswer ? '"' : ""}  ${text}  ${
        index === this.question.value.length - 1 && this.hasAnswer ? '"' : ""
      }`;
    },
    /**
     * Метод проверяет была ли уже отправлена оценка.
     * Если да, то снимается предыдущая оценка и ставится новая.
     *
     * @param {string} mark - лайк/дизлайк
     */
    sendMark(mark) {
      mark === "like"
        ? this.question.isDisliked && this.$emit("dislike")
        : this.question.isMarked && this.$emit("like");

      this.$emit(mark);
    },
  },
};
</script>

<style lang="scss" module>
@mixin lines {
  position: absolute;
  top: 50%;
  display: block;
  width: 35%;
  content: "";
  border-top: 1px solid $gray-lighten;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 15px 10px;
  margin-bottom: 15px;
  background: $white;
  border: 1px solid $gray-lighten;
  border-radius: 5px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > :last-child {
    margin-left: 5px;
  }
}

.status {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 7px;
  align-items: center;
  margin-right: auto;
  font-weight: 600;

  .userInfo {
    i {
      font-size: 10px;
    }

    .userInfoModal {
      display: none;
      position: absolute;
      z-index: 2;
      padding: 8px 15px 10px;
      background: #fff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      min-width: 50%;

      .userAvatar,
      .userDefaultAvatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .userDefaultAvatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--base-color);
        margin: auto;

        i {
          font-size: 40px;
          color: var(--base-color);
        }
      }

      .userAvatar {
        display: block;
        margin: auto;
      }

      p {
        text-align: center;
        margin: 10px 0;
      }
    }

    &:hover .userInfoModal {
      display: block;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.footer {
  margin-top: 15px;

  & > div {
    margin: 0 0 5px 0;
  }
}

.answer {
  padding-left: 12px;
  margin-left: 6px;
  word-break: break-word;
  border-left: 1px solid $gray;
}

.container_answered {
  background: $gray-lightest;
  border: none;
}

.container_line {
  padding: 0;
  border: none;

  .line {
    width: 100%;
    text-align: center;

    &::before {
      @include lines;

      left: 0;
    }

    &::after {
      @include lines;

      right: 0;
    }
  }
}
</style>
