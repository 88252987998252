<template>
  <smart-modal mask-icon :visible="visible" type="form" @close="$emit('close')">
    <template #full>
      <div ref="wrapper" :class="$style.wrapper" />
    </template>
  </smart-modal>
</template>

<script>
import SmartModal from "@/components/common/modals/SmartModal";

export default {
  name: "RoomQuestionsFullscreenModal",
  components: { SmartModal },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  width: 70vw;
  max-width: 900px;
  height: 85vh;
  margin: -30px -15px -15px;
}
</style>