<template>
  <smart-modal
    :visible="visible"
    type="form"
    @close="$emit('close')"
  >
    <template #full>
      <div :class="$style.header">
        <ws-button icon @click="$emit('close')">
          <ws-icon xxl light color="default">times</ws-icon>
        </ws-button>
      </div>
      <div :class="$style.body">
        <span>{{ $t("SpeakerQuestions.questionModerate") }}</span>
      </div>
      <div :class="$style.footer">
        <ws-button
          color="primary"
          block
          lg
          @click="$emit('close')"
        >
          {{ $t("common.accept") }}
        </ws-button>
      </div>
    </template>
  </smart-modal>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";
import SmartModal from "@/components/common/modals/SmartModal";

export default {
  name: "RoomQuestionsModerationModal",
  components: { WsIcon, WsButton, SmartModal },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-top: -15px;
}

.body {
  flex: 1 1 auto;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @media (min-width: 900px) {
    width: 345px;
  }
}

.footer {
  flex: 0 0 auto;
}
</style>