<template>
  <div :class="$style.current">
    <label :class="$style.speaker">
      <ws-avatar :size="20" :src="currentSpeaker.photo || ''" :title="currentSpeaker.fio" />
      <span :class="$style.speaker__name">{{ currentSpeaker.fio }}</span>
      <ws-tooltip
        ref="tooltip"
        theme="ws-transparent"
        trigger="click"
        placement="bottom-start"
        interactive
        arrow
        class="question-speakers-select"
        @show="selectOpened = true"
        @hide="selectOpened = false"
      >
        <template #target>
          <ws-button
            v-if="speakers.length > 1"
            icon
            color="default"
            sm
            :class="$style.speaker__button"
          >
            <ws-icon light md color="default">
              {{ selectOpened ? "chevron-down" : "chevron-up" }}
            </ws-icon>
          </ws-button>
        </template>
        <template #content>
          <div :class="$style.speakers">
            <label v-for="speaker in speakers" :key="speaker.id" :class="$style.speaker">
              <ws-avatar :size="20" :src="speaker.photo || ''" :title="speaker.fio" />
              <span :class="$style.speaker__name">{{ speaker.fio }}</span>
              <ws-radio
                :class="$style.speaker__radio"
                sm
                :value="speaker.id"
                :current-value="currentSpeaker.id"
                @input="setCurrentSpeaker(speaker)"
              />
            </label>
          </div>
        </template>
      </ws-tooltip>
    </label>
  </div>
</template>

<script>
import { WsTooltip } from "@whenspeak/ui-kit";
import WsRadio from "@/components/base/WsRadio";
import WsAvatar from "@/components/chat/components/Avatar";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";

export default {
  name: "QuestionsSpeakerSelect",
  components: { WsIcon, WsAvatar, WsTooltip, WsButton, WsRadio },
  props: {
    speakers: {
      type: Array,
      default: () => [],
    },
    currentSpeaker: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectOpened: false,
    };
  },
  methods: {
    setCurrentSpeaker(speaker) {
      this.$emit("set-current-speaker", speaker);
      this.$refs.tooltip.hide();
    },
  },
};
</script>

<style lang="scss" module>
:global {
  .question-speakers-select {
    margin-left: 2px;

    .tippy-arrow {
      z-index: 1;
      color: $white;
    }
  }
}

.current {
  min-width: 25px;
  max-width: 165px;
  height: 20px;
  overflow: hidden;
}

.speakers {
  display: flex;
  flex-direction: column;
  width: 250px;
  max-height: 150px;
  padding: 10px 10px 0;
  margin: -9px -5px;
  overflow: scroll;
  background: $white;
  border-radius: 5px;
  box-shadow: $box-shadow-common;
}

.speaker {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;

  &__name {
    flex: 1 1 auto;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button {
    height: 20px;

    &::before {
      background: transparent;
      border: none;
    }
  }

  &__radio {
    margin-left: 2px;
  }
}
</style>
