<template>
  <div
    class="text-md"
    :class="[
      $style.container,
      {
        [$style.container_screen]: screen,
      },
    ]"
  >
    <loader v-if="pending" center />
    <div v-else-if="error" class="absolute-center" :class="$style.error">
      <div :class="$style.error__message">{{ $t("SpeakerQuestions.error.fetchQuestions") }}</div>
      <ws-button color="primary" outlined @click="$emit('refresh')">{{ $t('common.update') }}</ws-button>
    </div>
    <template v-else>
      <div v-if="$slots.header" :class="$style.header">
        <slot name="header" />
      </div>
      <div :class="$style.body">
        <slot name="body" />
        <slot />
      </div>
      <div v-if="$slots.footer" :class="$style.footer">
        <slot name="footer" />
      </div>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/common/elements/Loader";
import WsButton from "@/components/base/WsButton";

export default {
  name: "LayoutQuestions",
  components: { WsButton, Loader },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    screen: { // Screen mode
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
}

.header {
  z-index: 9;
  flex: 0 0 auto;
  padding: 15px;
  box-shadow: $box-shadow-common;
}

.body {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.footer {
  z-index: 9;
  flex: 0 0 auto;
}

.error {
  text-align: center;
  white-space: nowrap;

  &__message {
    margin-bottom: 20px;
  }
}

.container_screen {
  width: 740px;
  padding: 35px 0 0;
  margin: 0 auto;

  .header {
    box-shadow: none;
  }

  .footer {
    display: none;
  }
}
</style>