<template>
  <div
    v-on-clickaway="closeQuestionMessenger"
    :class="[
      $style.container,
      { [$style.container_expanded]: visibleQuestionMessenger },
    ]"
  >
    <room-questions-messenger
      :visible="visibleQuestionMessenger"
      :message="message"
      :allow-anonymity="allowAnonymity"
      @close="toggleQuestionModal"
      @send-message="sendMessage"
    />
    <ws-button
      v-if="!visibleQuestionMessenger"
      block
      lg
      color="primary"
      @click="toggleQuestionModal"
    >
      {{ $t("SpeakerQuestions.askQuestion") }}
    </ws-button>
    <ws-button
      v-else
      class="flex"
      block
      lg
      color="primary"
      :disabled="sendMessageBlocked"
      @click="sendMessage"
    >
      <span>{{ $t("common.send") }}</span>
      <span
        v-if="countDown.intervalId"
        :class="$style.counter"
      >
        {{ countDown.timeCounter }}
      </span>
    </ws-button>
    <room-questions-moderation-modal
      :visible="visibleModerationModal"
      @close="visibleModerationModal = false"
    />
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import WsButton from "@/components/base/WsButton";
import RoomQuestionsMessenger from "@/components/room/questions/RoomQuestionsMessenger";
import RoomQuestionsModerationModal from "@/components/room/questions/RoomQuestionsModerationModal";

const SEND_MESSAGE_COUNT_DOWN = 30000; // Задержка между отправками сообщения

export default {
  name: "QuestionsFooter",
  components: {
    RoomQuestionsModerationModal,
    RoomQuestionsMessenger,
    WsButton,
  },
  mixins: [clickaway],
  props: {
    currentSpeaker: {
      type: Object,
      default: () => ({}),
    },
    speakers: {
      type: Array,
      default: () => [],
    },
    allowAnonymity: {
      type: Boolean,
      default: false,
    },
    moderationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleQuestionMessenger: false,
      visibleModerationModal: false,
      countDown: {
        timestamp: null,
        timeCounter: "",
        intervalId: null,
      },
      message: {
        text: "",
        anonymous: false,
      },
    };
  },
  computed: {
    sendMessageBlocked() {
      return !this.message.text || !!this.countDown.intervalId;
    },
  },
  methods: {
    closeQuestionMessenger() {
      this.visibleQuestionMessenger = false;
    },
    toggleQuestionModal() {
      this.visibleQuestionMessenger = !this.visibleQuestionMessenger;
    },
    sendMessage() {
      if (this.sendMessageBlocked) return;
      this.setCoolDown();
      this.toggleQuestionModal();
      this.$emit("send-message", { ...this.message, speakerID: this.currentSpeaker?.id });
      setTimeout(() => {
        this.message.text = "";
      }, 300);
      if (this.moderationEnabled) {
        this.visibleModerationModal = true;
      }
    },
    setCoolDown() {
      this.countDown.timestamp = new Date().getTime() + SEND_MESSAGE_COUNT_DOWN;
      this.countDown.intervalId = setInterval(this.countDownProcess, 1000);
    },
    countDownProcess() {
      const diff = this.countDown.timestamp - new Date().getTime();
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      this.countDown.timeCounter = `${seconds}`.padStart(2, "0");
      if (seconds <= 0) {
        clearInterval(this.countDown.intervalId);
        this.countDown.timeCounter = "";
        this.countDown.intervalId = null;
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: 15px;
  box-shadow: $box-shadow-common;
  transition: all 0.5s;
  background-color: $white;

  &_expanded {
    border-radius: 15px 15px 0 0;
  }
}

.counter {
  margin-left: auto;
}
</style>