<template>
  <div :class="$style.container">
    <transition name="fade">
      <loader v-if="pending" center />
      <transition-group
        v-else-if="questions.length"
        :key="speakerId"
        :class="$style.questions"
        name="questions-flip-list"
        tag="div"
      >
        <questions-item
          v-for="question in questions"
          :key="question.id"
          :class="{ 'questions-item': !question.isLine }"
          :question="question"
          :dislikes-enabled="dislikeEnabled"
          @like="$emit('set-like', question)"
          @dislike="$emit('set-dislike', question)"
        />
      </transition-group>
      <div v-else class="absolute-center" :class="$style.noQuestions">
        <div :class="$style.noQuestions__title">{{ $t("SpeakerQuestions.noQuestions") }}</div>
        <div :class="$style.noQuestions__subTitle">{{ $t("SpeakerQuestions.askFirst") }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionsItem from "@/components/common/questions/QuestionsItem";
import Loader from "@/components/common/elements/Loader";

export default {
  name: "Questions",
  components: { Loader, QuestionsItem },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    speakerId: {
      type: Number,
      default: 0,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    dislikeEnabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
:global {
  .questions-flip-list-move {
    transition: transform 0.5s;
  }

  .questions-item {
    transition: all 0.5s;
  }

  .questions-flip-list-enter,
  .questions-flip-list-leave-to {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.container {
  flex: 1 0 100%;
  overflow: auto;
}

.questions {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.noQuestions {
  text-align: center;
  white-space: nowrap;

  &__title {
    margin-bottom: 5px;
    font-size: 1.4em;
    font-weight: 600;
    line-height: 1.4em;
  }

  &__subTitle {
    color: $gray-darken;
  }
}
</style>
