<template>
  <layout-questions :screen="screen" :pending="pending" :error="error" @refresh="initQuestions">
    <template #header>
      <questions-header
        v-if="!record"
        :fullscreen="isFullscreen"
        :screen="screen"
        :sort="sortIsOff"
        :current-speaker="currentSpeaker"
        :speakers="speakers"
        @sorting="setSpeakerQuestionSort"
        @set-current-speaker="setCurrentSpeaker"
        @fullscreen="fullscreen"
      />
    </template>
    <template #body>
      <questions
        :pending="questionsPending"
        :speaker-id="+currentSpeaker.id"
        :questions="storeQuestions"
        :dislike-enabled="availableRoomModules.allowDislikes"
        @set-like="!screen && !record && setQuestionMark($event)"
        @set-dislike="!screen && !record && setQuestionDislike($event)"
      />
      <room-questions-fullscreen-modal
        v-if="$mqLaptop && !screen"
        ref="fullscreen"
        :visible="isFullscreen"
        @close="fullscreen"
      />
    </template>
    <template #footer>
      <questions-footer
        v-if="!screen && !record"
        :current-speaker="currentSpeaker"
        :speakers="speakers"
        :allow-anonymity="availableRoomModules.allowAnonymity"
        :moderation-enabled="availableRoomModules.questionPreModeration"
        @set-current-speaker="setCurrentSpeaker"
        @send-message="sendSpeakerQuestion"
      />
    </template>
  </layout-questions>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { questions, room, speakers, socket } from "@/store/modules/store.namespaces";
import { QUESTIONS } from "@/store/modules/questions/getter-types";
import {
  GET_USER_QUESTIONS,
  SEND_QUESTION,
  SEND_QUESTION_DISLIKE,
  SEND_QUESTION_LIKE,
} from "@/store/modules/questions/action-types";
import { CHANGE_CURRENT_SPEAKER, GET_SPEAKERS } from "@/store/modules/speakers/action-types";
import { RESET_SPEAKERS } from "@/store/modules/speakers/mutation-types";
import { RESET_USER_QUESTIONS, SET_QUESTIONS_SORT } from "@/store/modules/questions/mutation-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import LayoutQuestions from "@/components/layouts/LayoutQuestions";
import QuestionsHeader from "@/components/common/questions/QuestionsHeader";
import Questions from "@/components/common/questions/Questions";
import QuestionsFooter from "@/components/common/questions/QuestionsFooter";
import RoomQuestionsFullscreenModal from "@/components/room/questions/RoomQuestionsFullscreenModal";

export default {
  name: "ContainerQuestions",
  components: {
    RoomQuestionsFullscreenModal,
    QuestionsFooter,
    QuestionsHeader,
    Questions,
    LayoutQuestions,
  },
  props: {
    screen: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pending: false,
      error: false,
      questionsPending: false,
      isFullscreen: false,
      questionsUpdateIntervalId: null,
      parentNode: null,
    };
  },
  computed: {
    ...mapState(room, {
      roomNumber: "roomNumber",
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(questions, {
      sortIsOff: "sort",
    }),
    ...mapState(speakers, {
      currentSpeaker: "currentSpeaker",
      speakers: "speakers",
    }),
    ...mapGetters(questions, {
      storeQuestions: QUESTIONS,
    }),
    ...mapState(socket, {
      socketConnected: "isConnected",
    }),
  },
  watch: {
    roomNumber() {
      this.initQuestions();
    },

    /**
     * При переподключении пользователя обновляем вопросы
     */
    socketConnected() {
      this.socketConnected && this.initQuestions();
    },
  },
  created() {
    this.initQuestions();
  },
  mounted() {
    this.parentNode = this.$el.parentNode;
  },
  beforeDestroy() {
    this.clearQuestionsModule();
  },
  methods: {
    ...mapMutations(questions, {
      setSpeakerQuestionSort: SET_QUESTIONS_SORT,
    }),
    ...mapActions(questions, {
      getUserQuestions: GET_USER_QUESTIONS,
      setQuestionMark: SEND_QUESTION_LIKE,
      setQuestionDislike: SEND_QUESTION_DISLIKE,
      sendSpeakerQuestion: SEND_QUESTION,
    }),
    ...mapActions(speakers, {
      getSpeakersList: GET_SPEAKERS,
      changeCurrentSpeaker: CHANGE_CURRENT_SPEAKER,
    }),
    ...mapMutations(speakers, {
      resetSpeakers: RESET_SPEAKERS,
    }),
    ...mapMutations(questions, {
      resetUserQuestions: RESET_USER_QUESTIONS,
    }),
    async initQuestions() {
      try {
        this.error = false;
        this.pending = true;
        this.clearQuestionsModule();
        await this.getSpeakersList();
        this.getUserQuestions();
      } catch {
        this.error = true;
      } finally {
        this.pending = false;
      }
    },
    async setCurrentSpeaker(speaker) {
      try {
        this.questionsPending = true;
        await this.changeCurrentSpeaker(speaker);
      } finally {
        this.questionsPending = false;
      }
    },
    clearQuestionsModule() {
      if (this.questionsUpdateIntervalId) {
        clearInterval(this.questionsUpdateIntervalId);
      }
      this.resetUserQuestions();
      this.resetSpeakers();
      this.$emit("fullscreen", false);
    },
    fullscreen() {
      const { wrapper } = this.$refs.fullscreen.$refs;
      this.isFullscreen = !this.isFullscreen;
      this.$emit("fullscreen", this.isFullscreen);
      if (this.isFullscreen) {
        wrapper.appendChild(this.$el);
      } else {
        this.parentNode.appendChild(this.$el);
        wrapper.appendChild(this.$el.cloneNode(true));
        setTimeout(() => {
          wrapper.innerHTML = "";
          if (this.isFullscreen) {
            wrapper.appendChild(this.$el);
          }
        }, 200);
      }
    },
  },
};
</script>
