<template>
  <div
    :class="[
      $style.container,
      {
        [$style.container_screen]: screen,
        [$style.container_visibleSelect]: hasSpeakers,
      },
    ]"
  >
    <div v-if="screen" :class="$style.title">{{ $t("common.questions") }}</div>
    <questions-speaker-select
      v-if="hasSpeakers"
      :speakers="speakers"
      :current-speaker="currentSpeaker"
      @set-current-speaker="$emit('set-current-speaker', $event)"
    />
    <div :class="$style.right">
      <span :class="$style.right__text">
        {{ hasSpeakers && !screen && !fullscreen ? $t("common.sorting") : $t("SpeakerQuestions.autoUpdate") }}
      </span>
      <ws-toggle :value="sort" @input="$emit('sorting', $event)" />
      <ws-button
        v-if="!fullscreen && !screen && $mqLaptop"
        :class="$style.right__fullscreen"
        :title="$t('SpeakerQuestions.title.enterFullscreen')"
        icon
        color="default"
        @click.stop="$emit('fullscreen')"
      >
        <ws-icon>expand</ws-icon>
      </ws-button>
    </div>
  </div>
</template>

<script>
import WsToggle from "@/components/base/WsToggle";
import QuestionsSpeakerSelect from "@/components/common/questions/QuestionsSpeakerSelect";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "QuestionsHeader",
  components: { WsIcon, WsButton, QuestionsSpeakerSelect, WsToggle },
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    screen: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    currentSpeaker: {
      type: Object,
      default: () => ({}),
    },
    speakers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasSpeakers() {
      return this.speakers.length && !this.currentSpeaker?.is_system;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 2em;
  font-weight: 400;
  line-height: 2em;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__text {
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__fullscreen {
    margin-right: -8px;
    margin-left: 5px;
  }
}

.container_screen {
  flex-wrap: wrap;

  .right {
    width: auto;
    color: $gray-darken;
  }
}

.container_visibleSelect {
  .right {
    width: auto;
  }
}

.container_screen.container_visibleSelect {
  .title {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>